import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import { setUser } from "../redux/features/userSlice.js";
import Layout from "../components/Layout/Layout";
import axios from "axios";
import getUserData from "../utils/userDataService.js";
import TollIcon from "@mui/icons-material/Toll";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";
import "./ProductInfo.css";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [popup, setPopup] = useState(false);
  const [userIdImage, setUserIdImage] = useState(false);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("WALLET");
  const [paymentOptions, setPaymentOptions] = useState("");
  //!NEW STATE
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [orderId, setOrderId] = useState(false);
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [balance, setBalance] = useState("");
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [data, setData] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [couponApplied, setCouponApplied] = useState(null);
  const [discount, setDiscount] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [couponName, setCouponName] = useState("");
  const [packCategory, setPackCategory] = useState(null);
  const [allCategory, setAllCategory] = useState(null);

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
    getAllCoupons();
  }, []);

  const getAllCoupons = async () => {
    try {
      const res = await axios.get("/api/admin/get-coupons");
      if (res.data.success) {
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function applyCoupon(e) {
    e.preventDefault();
    if (data?.length === 0 || data === null) {
      return message.error("No Coupon Found");
    }
    if (coupon === "") {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 1500);
      return;
    }
    // find coupon
    const couponCode = data && data?.find((item) => item.name === coupon);
    setCouponName(couponCode?.name);

    if (couponCode?.minValue >= selectedPrice) {
      message.error(`Minimum order value is: ${couponCode?.minValue}`);
      return;
    }
    //
    if (coupon) {
      setCouponApplied(true);
      setDiscount(couponCode?.discount);
      setFinalAmount(selectedPrice - couponCode?.discount);
      message.success("Coupon applied success");
    } else {
      message.error("No coupon found");
    }
  }

  const removeDiscount = () => {
    setCouponApplied(false);
    setFinalAmount((prev) => prev + discount);
  };

  // ==== COUPON

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      setFinalAmount(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      if (couponApplied) {
        setCouponApplied(false);
      }
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      setFinalAmount(price);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
      if (couponApplied) {
        setCouponApplied(false);
      }
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        const defaultCategory = res.data.data?.cost?.[0]?.packCategory;
        setPackCategory(defaultCategory);
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setFinalAmount(defaultPrice);
        setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, [user]);

  const generateOrderId = () => {
    const numbers = "01234567"; // 8 numbers
    const randomNumbers = Array.from({ length: 7 }, () =>
      numbers.charAt(Math.floor(Math.random() * numbers.length))
    );
    const now = new Date();
    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0"); // getMonth() is 0-indexed
    const year = String(now.getFullYear()).slice(2); // last two digits of the year
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const orderId = `${year}${month}${day}${seconds}${randomNumbers.join("")}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter User ID" : "Enter (   Zone ID   )"}`
      );
    }
    try {
      // setLoading(true);
      const idPro = productId.split("&")[0];
      const object = {
        region: product?.apiName === "smileOne" ? product?.region : "brazil",
        userid: userId,
        zoneid: zoneId,
        productid: product?.apiName === "smileOne" ? idPro : "13",
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function checkPlaceOrder(e) {
    if (product?.playerCheckBtn === "yes") {
      if (playerCheck === null) {
        return message.error("Check your playername");
      }
    }
    if (product?.api === "no") {
      if (userId === "") {
        return message.error("Some Fields are missing");
      }
    } else if (product.api === "yes" && product?.apiName === "moogold") {
      if (product?.gameName === "15145") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Enter Zone ID");
        }
      }
    } else if (product.api === "yes" && product?.apiName === "yokcash") {
      if (
        product?.gameName === "MLBB" ||
        product?.gameName === "Mobile Legends"
      ) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Enter Zone ID");
        }
      }
      if (product?.gameName === "Honor Of Kings") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      }
      if (
        product?.gameName === "Honkai Star Rail" ||
        product?.gameName === "Genshin Impact"
      ) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
      if (product?.gameName === "PUBG Mobile") {
        if (userId === "") {
          return message.error("Enter Character ID");
        }
      }
    } else {
      if (userId === "") {
        return message.error("Enter User ID");
      }
      if (zoneId === "") {
        return message.error("Enter Zone ID");
      }
      if (playerCheck === null) {
        return message.error("Check Your Player Name");
      }
    }

    if (product?.api === "yes") {
      if (product?.apiName === "yokcash") {
        if (mode === "UPI") {
          handleYokcashUpiOrder(e);
        } else {
          handleYokcashWalletOrder(e);
        }
      } else if (product?.apiName === "smileOne") {
        if (mode === "UPI") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      } else if (product?.apiName === "moogold") {
        if (mode === "UPI") {
          handleMoogoldUpiOrder(e);
        } else {
          handleMoogoldWalletOrder(e);
        }
      }
    } else {
      if (mode === "UPI") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  // yokcash
  async function handleYokcashUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: finalAmount,
        product_name: amount,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://zleeofficial.in/api/yok/status?orderId=${orderId}`,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      const response = await axios.post("/api/yok/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleYokcashWalletOrder(e) {
    if (parseInt(balance) < parseInt(finalAmount)) {
      return message.error("Balance is less for this order");
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: finalAmount,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
      };

      setLoading(true);
      const res = await axios.post("/api/yok/wallet", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setOrderSuccess(true);
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // smile
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: finalAmount,
        product_name: product?.region,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://zleeofficial.in/api/smile/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          selectedPrice,
      };

      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(finalAmount)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    const orderObject = {
      orderId: orderId,
      userid: userId,
      zoneid: zoneId,
      productid: productId,
      region: product.region,
      customer_email: user?.email,
      customer_mobile: user?.mobile,
      pname: product?.name,
      amount: amount,
      price: finalAmount,
      ogPrice: selectedPrice,
    };

    setLoading(true);
    const res = await axios.post("/api/smile/wallet", orderObject, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (res.data.success) {
      message.success(res.data.message);
      setOrderSuccess(true);
      setLoading(false);
    } else {
      setLoading(false);
      message.error(res.data.message);
    }
  };

  // moogold
  async function handleMoogoldUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: finalAmount,
        product_name: product?.gameName,
        customer_name: user?.fname,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        callback_url: `https://zleeofficial.in/api/moogold/status?orderId=${orderId}`,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          selectedPrice,
      };

      const response = await axios.post("/api/moogold/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function handleMoogoldWalletOrder(e) {
    if (parseInt(balance) < parseInt(finalAmount)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "yes",
        orderId: orderId,
        txn_amount: finalAmount,
        product_name: product?.gameName,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId +
          "@" +
          zoneId +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount +
          "@" +
          selectedPrice,
      };
      setLoading(true);
      const res = await axios.post("/api/moogold/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        setOrderSuccess(true);
        navigate("/user-dashboard");
      } else {
        setLoading(false);
        setOrderSuccess(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      setOrderSuccess(false);
      console.log(error);
    }
  }

  // manual
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: finalAmount,
        txn_note: userId + "@" + amount,
        product_name: product?.name,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
        customer_email: user?.email,
        callback_url: `https://zleeofficial.in/api/manual/status?orderId=${orderId}`,
      };

      const response = await axios.post("/api/manual/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
      } else {
        message.error(response.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(selectedPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: userId,
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: finalAmount,
      };

      setLoading(true);
      const res = await axios.post("/api/manual/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoading(false);
        message.success(res.data.message);
        setOrderSuccess(true);
      } else {
        message.error(res.data.message);
        setLoading(false);
        localStorage.setItem("orderProcess", "no");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      localStorage.setItem("orderProcess", "no");
    }
  };

  const getAllCategory = async () => {
    try {
      const res = await axios.get("/api/packcategory/get-pack-category", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setAllCategory(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const uniqueCategories = Array.from(
    new Map(product?.cost?.map((item) => [item.packCategory, item])).values()
  );

  return (
    <Layout>
      {loading ? (
        <div className="loading-container">
          <div class="spinner">
            <div class="spinner1"></div>
          </div>
          <h4>Processing..</h4>
        </div>
      ) : orderSuccess ? (
        <div className="order-succcess-container">
          <div className="heading">
            <CheckCircleIcon className="icon" />
            <h5>Order Successful</h5>
            <button onClick={() => setOrderSuccess(false)}>Order Again</button>
          </div>
          <div className="order-recpt">
            <div className="order-item">
              <span>Product Name</span>
              <span>{product?.name}</span>
            </div>
            <div className="order-item">
              <span>Order Id</span>
              <span>{orderId}</span>
            </div>
            <div className="order-item">
              <span>User Id</span>
              <span>{userId}</span>
            </div>
            {zoneId !== "" && (
              <div className="order-item">
                <span>Zone Id</span>
                <span>{zoneId}</span>
              </div>
            )}
            <div className="order-item">
              <span>Pack</span>
              <span>{amount}</span>
            </div>
            <div className="order-item">
              <span>Wallet Balance</span>
              <span>{balance}</span>
            </div>
            <div className="order-item">
              <span>Balance After Order</span>
              <span>{balance - selectedPrice}</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="product-info-container">
          {/* BANNER IMAGE */}
          {/* BANNER IMAGE */}
          {/* BANNER IMAGE */}
          <div
            className="p-info-container area"
            style={{
              backgroundImage: `url(https://zleeofficial.in/${product?.bannerImage?.replace(
                /\\/g,
                "/"
              )})`,
              backgroundSize: "cover",
              backgroundPosition: "top",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="pro-img">
              <img src={`https://zleeofficial.in/${product?.image}`} alt="" />
              <div>
                <h2 className="m-0">{product?.name}</h2>
                <h6 className="m-0 d-none d-md-none d-lg-block">
                  {product?.descTwo}
                </h6>
                <div className="features">
                  <button>
                    <SupportAgentIcon className="icon" />
                    24/7 Chat Support
                  </button>
                  <button>
                    <AssuredWorkloadIcon className="icon" />
                    Safe Payment
                  </button>
                  <button>
                    <VerifiedUserIcon className="icon" />
                    Official Store
                  </button>
                  <button>
                    <WorkspacePremiumIcon className="icon" />
                    Service Guarantee
                  </button>
                  <button>
                    <ElectricBoltIcon className="icon" />
                    Instant Delivery
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* INS IMAGE  */}
          {/* INS IMAGE  */}
          {/* INS IMAGE  */}
          <div className="instruction-image">
            <button onClick={() => setPopup(!popup)}>
              <InfoIcon className="icon me-1" />
              How to Purchase
            </button>
            <div className={`ins-popup ${popup && "active"}`}>
              <img src={`/${product?.instructionImage}`} alt="" />
              <CancelIcon
                className="icon close-icon"
                onClick={() => setPopup(!popup)}
              />
            </div>
          </div>
          {/* <div className="pic">
            <div className="product-info-img bg-fields">
              <div className="game-name">
                <img src={`/${product?.image}`} alt="" />
                <div>
                  <h4>{product?.name}</h4>
                </div>
              </div>
              <hr className="text-white" />
              <p className="m-0">
                <small>Instruction:</small>
              </p>
              <p>{product?.desc}</p>
            </div>
          </div> */}

          <div className="bg-fields w-100 product-desc">
            <p>{product?.desc}</p>
          </div>

          <div className="product-info-content mb-2">
            {/* ====================== FIELDS ===============  */}
            {/* ====================== FIELDS ===============  */}
            <div className="bg-fields">
              <h5>
                Enter IDs
                <HelpIcon
                  className="icon"
                  onClick={() => setUserIdImage(!userIdImage)}
                />
                <div className={`helpimg ${userIdImage && "active"}`}>
                  <CancelIcon onClick={() => setUserIdImage(!userIdImage)} />
                  <img src={product?.userIdImage} alt="" />
                </div>
              </h5>
              {product?.apiName === "smileOne" ||
              (product?.apiName === "moogold" &&
                product?.gameName === "15145") ||
              (product?.apiName === "yokcash" &&
                (product?.gameName === "MLBB" ||
                  product?.gameName === "Mobile Legends")) ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="User ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <input
                    className="player-tag"
                    type="text"
                    name="zoneid"
                    placeholder="(    ZONE ID    )"
                    onChange={(e) => setZoneId(e.target.value)}
                    value={zoneId}
                  />
                </>
              ) : (product?.apiName === "moogold" &&
                  (product?.gameName === "428075" ||
                    product?.gameName === "4233885")) ||
                (product.apiName === "yokcash" &&
                  (product?.gameName === "Honkai Star Rail" ||
                    product?.gameName === "Genshin Impact")) ? (
                <>
                  <div className="d-flex align-items-center">
                    <input
                      className="player-tag"
                      type="text"
                      name="userId"
                      placeholder="USER ID"
                      onChange={(e) => setUserId(e.target.value)}
                      value={userId}
                    />
                  </div>
                  <select
                    name="zoneid"
                    className="form-select player-tag"
                    onChange={(e) => setZoneId(e.target.value)}
                  >
                    <option value="">Select Server</option>
                    <option value="America">America</option>
                    <option value="Asia">Asia</option>
                    <option value="Europe">Europe</option>
                    <option value="TW, HK, MO">TW, HK, MO</option>
                  </select>
                  <span className="text-danger">
                    {error &&
                      "First Check your username by clicking check button"}
                  </span>
                </>
              ) : (product?.apiName === "moogold" &&
                  product?.gameName === "5177311") ||
                (product?.apiName === "yokcash" &&
                  (product?.gameName === "Honor Of Kings" ||
                    product?.gameName === "PUBG Mobile")) ? (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tag}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              ) : product?.apiName === "yokcash" &&
                product?.gameName === "MLBB" ? (
                ""
              ) : (
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tag}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
              )}
              {product?.playerCheckBtn === "yes" && (
                <button className="buy-now" onClick={handleCheckPlayer}>
                  Check
                  {loading && (
                    <div class="spinner-grow spinner-grow-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              )}
              {playerCheck !== null && (
                <p className="playername text-success">
                  {playerCheck && "Username: " + playerCheck}
                </p>
              )}
            </div>

            {/* ====================== PACKAGE ===============  */}
            {/* ====================== PACKAGE ===============  */}
            <div className="bg-fields">
              {packCategory && <h5 className="mb-3">Choose Category</h5>}
              {packCategory && (
                <div className="pcategory">
                  {uniqueCategories.map((item, index) => {
                    const image = allCategory?.filter(
                      (elm) => elm?.category === item?.packCategory
                    )[0]?.image;
                    return (
                      <div key={index}>
                        <div
                          className={`pc ${
                            packCategory === item?.packCategory && "active"
                          }`}
                          onClick={() => setPackCategory(item?.packCategory)}
                        >
                          <img
                            src={`https://zleeofficial.in/${image}`}
                            alt=""
                          />
                          <p>{item?.packCategory}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {packCategory && <hr className="text-white" />}
              <h5>Select Package</h5>
              <div className="package-container">
                {product?.cost
                  ?.filter(
                    (item) =>
                      !packCategory ||
                      item?.packCategory?.includes(packCategory)
                  )
                  .map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setAmount(item.amount);
                          setPriceAndId(item.amount);
                        }}
                        key={index}
                        className={`amount ${
                          amount === item?.amount && "active"
                        }`}
                      >
                        <div className="pack-details">
                          <div className="pack">
                            <p>{item?.amount}</p>
                            <p>{item?.dataAmount}</p>
                          </div>
                          <div className="image">
                            <img src={item?.pimg} alt="pimg" />
                          </div>
                          <div className="price">
                            <p>
                              ₹
                              {user?.reseller === "yes"
                                ? item?.resPrice
                                : item?.price}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            {/* ====================== PAYMENT METHOD ===============  */}
            {/* ====================== PAYMENT METHOD ===============  */}
            <div className="bg-fields">
              <h5>Select Payment</h5>
              <div className="payment-container">
                <div
                  onClick={() => {
                    setMode("WALLET");
                    setPaymentOptions("");
                  }}
                  className={`payment wallet ${mode === "WALLET" && "active"}`}
                >
                  <TollIcon className="icon" />
                  wallet
                </div>
                <div
                  onClick={() => {
                    setMode("UPI");
                  }}
                  className={`payment upi ${mode === "UPI" && "active"}`}
                >
                  UPI
                </div>
              </div>
            </div>

            {/* <div className="bg-fields">
              {couponApplied ? (
                <>
                  <h5>Discount Applied</h5>
                  <div className="coupon-tag">
                    <p className="m-0">
                      {couponName} <CheckCircleOutlineIcon className="icon" />
                    </p>
                    <button className="remove-coupon" onClick={removeDiscount}>
                      Remove
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <h5>Apply Coupon</h5>
                  <div className="coupon-box">
                    <input
                      className="player-tag m-0"
                      type="text"
                      name="coupon"
                      placeholder="Enter Coupon"
                      onChange={(e) => setCoupon(e.target.value)}
                      value={coupon}
                    />
                    <button onClick={applyCoupon}>Apply</button>
                  </div>
                </>
              )}
              {error && coupon === "" && (
                <span className="text-danger">Enter valid coupon</span>
              )}
            </div> */}

            {/* ========================================= */}
            {/* BILL */}
            {/* BILL */}
            {/* BILL */}
            {/* BILL */}
            {/* BILL */}
            <div className="bg-fields">
              <div className="total-value">
                <h5>Buy Now</h5>
                <div className="text-end">
                  <p className="m-0">
                    <b>Rs. {finalAmount}</b>
                  </p>
                  <span>
                    <small>
                      Amount {amount} |{" "}
                      <span>
                        Using - {paymentOptions !== "" ? paymentOptions : mode}
                      </span>
                    </small>
                  </span>
                </div>
              </div>
              <div className="buy-btn-container">
                {user?.block === "yes" || product?.stock === "no" ? (
                  <button className="buy-now" style={{ opacity: "0.7" }}>
                    Out of Stock
                  </button>
                ) : !user ? (
                  <button
                    onClick={() => navigate("/login")}
                    className="buy-now"
                  >
                    Please Login First
                  </button>
                ) : (
                  <button onClick={checkPlaceOrder} className="buy-now">
                    BUY NOW
                  </button>
                )}
              </div>
            </div>

            {/* DESC */}
            {/* DESC */}
            {/* DESC */}
            {/* DESC */}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ProductInfo;
